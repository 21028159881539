<template>
  <div class="flex-grow-1 flex-basis-100 d-flex align-center">
    <column-mapper
      v-if="['amt-column', 'balance-slash-amount-column'].includes(footerColumn.type)"
      class="flex-grow-1"
      :column="footerColumn"
      :value="footerColumn[summaryProp]"
    />
    <span v-else-if="footerColumn.type == 'number-column'">{{footerColumn[summaryProp]}}</span>
  </div>
</template>

<script>
export default {
  components: {
    ColumnMapper: () => import('./column-mapper')
  },
  computed: {
    summaryProp () {
      return this.selectedSummary ? 'selectedSummary' : 'summary'
    }
  },
  props: {
    footerColumn: Object,
    selectedSummary: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.flex-basis-100
  flex-basis 100%
  min-height 25px
</style>
